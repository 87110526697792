import React, { useRef, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"

import Seo from "../components/seo"

const Contact = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  })
  const ref = useRef(null)

  const onSubmit = data => {
    setLoading(true)
    let formData = new FormData()
    for (const key in data) {
      formData.append(key, data[key])
    }
    fetch(
      "https://script.google.com/macros/s/AKfycbzvwa5hApB1lMZdQZ7gM4vptu_DBfXtK95lg7Fmr5uACuKxRaRA/exec",
      { method: "POST", body: formData }
    )
      .then(response => {
        reset && reset()
        alert(t("contact.success"))
        setLoading(false)
      })
      .catch(error => {
        alert(t("contact.failed"))
        console.error("Error!", error.message)
        setLoading(false)
      })
  }

  if (errors) console.log(errors)

  return (
    <>
      <Seo
        title="布蘭登公爵 Duke Blandon｜中式白酒"
        description="布蘭登公爵將把新型態中式白酒推進世界頂端 ，打破中式白酒-高粱的傳統印象。凍蒜高粱只是布蘭登公爵的開端，將挖掘更多顛覆味蕾的極佳風味。類型：中式白酒、高粱"
      />
      <div className="p-10 pb-40 md:px-72">
        <div className="flex flex-col justify-center items-center mb-12">
          <StaticImage
            src="../images/Desktop/05.LOGO_Gold@2x.png"
            width={76.88}
            alt="gold-logo"
            className="mb-4"
            placeholder="blurred"
          />
          <h1
            className="font-normal text-lg md:mb-16"
            style={{ letterSpacing: "0.3em" }}
          >
            {t("contact.title1")}
          </h1>
          <p
            className="font-light text-sm md:text-base mb-5 md:text-left md:self-start"
            style={{ letterSpacing: "0.3em" }}
          >
            {t("contact.title1Des1")}
          </p>
          <p
            className="font-light text-sm md:text-base mb-5 md:text-left"
            style={{ letterSpacing: "0.3em" }}
          >
            {t("contact.title1Des2")}
          </p>
          <p
            className="font-light text-sm md:text-base mb-20 md:text-left"
            style={{ letterSpacing: "0.3em" }}
          >
            {t("contact.title1Des3")}
          </p>
          <form ref={ref} className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-5 flex flex-col w-full">
              <label className="mb-3" htmlFor="subject">
                {t("contact.title2")}
              </label>
              <input
                className={`rounded-sm px-1 py-2 bg-input ${
                  !!errors.subject ? "border border-red-500" : ""
                }`}
                name="subject"
                {...register("subject", { required: true })}
              />
            </div>
            <div className="mb-5 flex flex-col w-full">
              <label className="mb-3" htmlFor="name">
                {t("contact.title3")}
              </label>
              <input
                className={`rounded-sm px-1 py-2 bg-input ${
                  !!errors.name ? "border border-red-500" : ""
                }`}
                name="name"
                {...register("name", { required: true })}
              />
            </div>
            <div className="mb-5 flex flex-col w-full">
              <label className="mb-3" htmlFor="location">
                {t("contact.title4")}
              </label>
              <input
                className={`rounded-sm px-1 py-2 bg-input ${
                  !!errors.location ? "border border-red-500" : ""
                }`}
                name="location"
                {...register("location", { required: true })}
              />
            </div>
            <div className="mb-5 flex flex-col w-full">
              <label className="mb-3" htmlFor="email">
                {t("contact.title5")}
              </label>
              <input
                className={`rounded-sm px-1 py-2 bg-input ${
                  !!errors.email ? "border border-red-500" : ""
                }`}
                name="email"
                type="email"
                {...register("email", {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i,
                    message: t("contact.emailError"),
                  },
                })}
              />
              {!!errors.email && (
                <p className="text-red-500">{errors.email.message}</p>
              )}
            </div>
            <div className="mb-5 flex flex-col w-full">
              <label className="mb-3" htmlFor="phone">
                {t("contact.title6")}
              </label>
              <input
                className={`rounded-sm px-1 py-2 bg-input ${
                  !!errors.phone ? "border border-red-500" : ""
                }`}
                name="phone"
                type="number"
                {...register("phone", { required: true })}
              />
              {!!errors.phone && (
                <p className="text-red-500">{errors.phone.message}</p>
              )}
            </div>
            <div className="mb-5 flex flex-col w-full">
              <label className="mb-3" htmlFor="content">
                {t("contact.title7")}
              </label>
              <textarea
                rows={5}
                className={`rounded-sm px-1 py-2 bg-input ${
                  !!errors.content ? "border border-red-500" : ""
                }`}
                name="content"
                {...register("content", { required: true })}
              />
            </div>
            <div className="flex items-center justify-center w-full">
              <button
                type="submit"
                className="rounded-md py-3 mt-9 bg-black text-white text-sm"
                style={{ width: 230 }}
              >
                {loading ? t("contact.title9") : t("contact.title8")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Contact
